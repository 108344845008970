import React from 'react'
import 'regenerator-runtime/runtime'
import dynamic from 'next/dynamic'

const DesignerApp = dynamic(() => import('../routes/Designs'), {
  ssr: false
})

const Routes = () => {
  return <DesignerApp />
}

export default Routes
